import React, { useState, useEffect } from "react";
import axios from "axios";
import { toast } from "react-toastify";

const AddReedemModal = ({ isOpen, onClose, onSuccess, initialData }) => {
  const [reedem, setReedem] = useState("");
  const [reward, setReward] = useState("");
  const [jumlah, setJumlah] = useState("");
  const [isEditing, setIsEditing] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    if (initialData) {
      setReedem(initialData.code);
      setReward(initialData.reward);
      setJumlah(initialData.max);
      setIsEditing(true);
    } else {
      setReedem("");
      setReward("");
      setJumlah("");
      setIsEditing(false);
    }
  }, [initialData]);
  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    const formData = {
      code: reedem,
      reward: reward,
      max: jumlah,
    };

    try {
      await axios.get("sanctum/csrf-cookie");
      if (isEditing && initialData?.id) {
        await axios.put(`api/reward/update/${initialData.id}`, formData);
        toast.success("Data berhasil diperbarui!");
        onSuccess({ ...formData, id: initialData.id }, isEditing);
      } else {
        const response = await axios.post("api/reward/store", formData);
        toast.success("Data berhasil disimpan!");
        onSuccess({ ...formData, id: response.data.id }, false);
      }
      onClose();
    } catch (err) {
      if (err.response && err.response.status === 409) {
        toast.error(
          err.response.data.message || "Conflict with existing data!"
        );
      } else {
        toast.error("Gagal menyimpan data!");
      }
    } finally {
      setIsLoading(false);
    }
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
      <div className="bg-white rounded-lg p-8">
        <h2 className="text-xl font-semibold mb-4">
          {isEditing ? "Edit Reedem" : "Tambah Reedem Baru"}
        </h2>
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <label className="block text-gray-700">Kode Reedem</label>
            <input
              type="text"
              value={reedem}
              onChange={(e) => setReedem(e.target.value)}
              className="w-full border rounded px-3 py-2"
              required
            />
          </div>
          <div className="mb-4">
            <label className="block text-gray-700">Reward</label>
            <input
              type="text"
              value={reward}
              onChange={(e) => setReward(e.target.value)}
              className="w-full border rounded px-3 py-2"
              required
            />
          </div>
          <div className="mb-4">
            <label className="block text-gray-700">
              Jumlah Maksimal Reedem
            </label>
            <input
              type="number"
              value={jumlah}
              onChange={(e) => setJumlah(e.target.value)}
              className="w-full border rounded px-3 py-2"
              required
              min="0"
            />
          </div>
          <div className="flex justify-end gap-2">
            <button
              type="button"
              onClick={onClose}
              className="bg-gray-400 text-white active:scale-95 duration-200 px-4 py-2 rounded mr-2">
              Cancel
            </button>
            <button
              disabled={isLoading}
              type="submit"
              className="bg-teal-600 flex items-center gap-1 text-white active:scale-95 duration-200 px-4 py-2 rounded">
              {isLoading ? (
                <>
                  <i className="fa fa-spinner animate-spin"></i>
                  <p>Loading..</p>
                </>
              ) : isEditing ? (
                "Update"
              ) : (
                "Save"
              )}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AddReedemModal;
