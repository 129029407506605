import React, { useState } from "react";
import { Sidebar } from "../Components/Sidebar";
import axios from "axios";
import { toast } from "react-toastify";

export const Profile = () => {
  // State untuk menyimpan input formulir
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [loading, setLoading] = useState(false);

  // Fungsi untuk menangani pembaruan kata sandi
  const handleUpdatePassword = async (e) => {
    e.preventDefault();

    if (newPassword !== confirmPassword) {
      toast.error("New passwords do not match!");
      return;
    }

    try {
      setLoading(true);
      await axios.get("sanctum/csrf-cookie");

      // Mengirim request untuk update password
      const response = await axios.post("api/user/update-password", {
        current_password: currentPassword,
        new_password: newPassword,
      });

      // Jika response status adalah 200, tampilkan pesan sukses
      if (response.status === 200) {
        toast.success("Password updated successfully!");
        setCurrentPassword("");
        setNewPassword("");
        setConfirmPassword("");
      } else {
        toast.error("Unexpected response status: " + response.status);
      }
    } catch (error) {
      // Menangani kesalahan dari respons jika ada
      if (error.response) {
        const { status, data } = error.response;

        if (status === 401) {
          toast.warn("Current password is incorrect!");
        } else if (status === 422) {
          const errors = data.validation_errors;
          // Pastikan errors ada dan memiliki format yang diharapkan
          if (errors) {
            // Looping melalui kesalahan dan menampilkan setiap pesan
            for (const [field, messages] of Object.entries(errors)) {
              messages.forEach((message) => {
                toast.warn(`${field}: ${message}`);
              });
            }
          } else {
            toast.error("Validation error occurred.");
          }
        } else {
          toast.error("An error occurred. Please try again.");
        }
      } else {
        // Menangani kesalahan lain jika tidak ada response
        toast.error("Failed to update password. Please try again.");
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="bg-gray-100 md:pt-10 md:pb-10 px-4 pt-4">
      <div className="lg:container lg:mx-auto">
        <div className="md:flex md:columns-2 md:gap-10">
          <Sidebar />
          <div className="bg-white rounded-xl md:w-5/6 p-6">
            <h2 className="text-xl font-bold mb-4">Update Password</h2>
            <form onSubmit={handleUpdatePassword}>
              <div className="mb-4">
                <label
                  className="block text-gray-700 text-sm font-bold mb-2"
                  htmlFor="current-password">
                  Current Password
                </label>
                <input
                  type="password"
                  id="current-password"
                  value={currentPassword}
                  onChange={(e) => setCurrentPassword(e.target.value)}
                  required
                  className="border border-gray-300 rounded p-2 w-full"
                />
              </div>
              <div className="mb-4">
                <label
                  className="block text-gray-700 text-sm font-bold mb-2"
                  htmlFor="new-password">
                  New Password
                </label>
                <input
                  type="password"
                  id="new-password"
                  value={newPassword}
                  onChange={(e) => setNewPassword(e.target.value)}
                  required
                  className="border border-gray-300 rounded p-2 w-full"
                />
              </div>
              <div className="mb-4">
                <label
                  className="block text-gray-700 text-sm font-bold mb-2"
                  htmlFor="confirm-password">
                  Confirm New Password
                </label>
                <input
                  type="password"
                  id="confirm-password"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  required
                  className="border border-gray-300 rounded p-2 w-full"
                />
              </div>
              <div className="mb-4 flex justify-end">
                <button
                  type="submit"
                  disabled={loading}
                  className="bg-yellow-600 duration-200 hover:bg-yellow-700 glass text-white p-2 rounded xl:w-1/3 w-full">
                  {loading ? "Updating..." : "Update Password"}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};
