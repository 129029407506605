import React from "react";
import "../../App.css";
const LoadingScreenHome = () => {
  return (
    <div className="background">
      <div className="loading-screen duration-1000 fade fade-in">
        <div className="loading-spinner"></div>
      </div>
    </div>
  );
};

export default LoadingScreenHome;
