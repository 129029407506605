import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { Wheel } from "react-custom-roulette";
import { toast } from "react-toastify";
import "../Assets/whell.css";
import winningSound from "../Audio/Winning Sound Effect.mp3"; // Import file audio
import barText from "../images/winner-bar.png";
import LoadingScreenHome from "./Components/LoadingScreenHome";

export const Home = ({ authCheck }) => {
  const [data, setData] = useState([]);
  const [mustSpin, setMustSpin] = useState(false);
  const [prizeNumber, setPrizeNumber] = useState(0);
  const [loading, setLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [reward, setReward] = useState("");
  const [phoneNumber, setPhoneNumber] = useState(null); // Gunakan satu state untuk nomor telepon

  const audioRef = useRef(null);
  const modalRef = useRef(null);
  const getPhoneNumberAPI = async () => {
    try {
      await axios.get("sanctum/csrf-cookie");
      const res = await axios.get("api/phone-number/get");
      console.log(res.data); // Periksa struktur data yang diterima
      setPhoneNumber(res.data.phone); // Sesuaikan jika struktur data berbeda
    } catch (err) {
      toast.error("Failed to fetch phone number!");
    }
  };

  useEffect(() => {
    const __fetchData = async () => {
      try {
        await axios.get("sanctum/csrf-cookie");
        const response = await axios.get("api/reward/view");
        const formattedData = response.data.map((item) => ({
          option: item.reward,
        }));
        setData(formattedData);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoading(false);
      }
    };

    getPhoneNumberAPI(); // Pastikan ini dipanggil
    __fetchData();
  }, []);

  const [formInput, setFormInput] = useState("");
  const handleSpinClick = async () => {
    if (!formInput.trim()) {
      toast.info("Enter a redeem code.");
      return;
    }

    if (!mustSpin) {
      try {
        const response = await axios.post("api/reward/find", {
          code: formInput,
        });

        if (response.status === 200) {
          // Menangani respons sukses
          setPrizeNumber(response.data.index);
          setReward(response.data.reward);
          setMustSpin(true);
          // Mainkan audio ketika spin diklik
          if (audioRef.current) {
            audioRef.current.play().catch((error) => {
              console.error("Error playing audio:", error);
            });
          }
        } else {
          toast.error("Unexpected response status: " + response.status);
        }
      } catch (error) {
        // Menangani kesalahan dari respons jika ada
        if (error.response) {
          const { status, data } = error.response;

          if (status === 404) {
            toast.error("Reward not found");
          } else if (status === 406) {
            toast.error("Redeem code expired.");
          } else if (status === 422) {
            toast.warn("Validation errors occurred.");
            const errors = data.validation_errors;
            if (errors) {
              for (const [field, messages] of Object.entries(errors)) {
                messages.forEach((message) => {
                  toast.warn(`${field}: ${message}`);
                });
              }
            }
          } else {
            toast.error("An error occurred. Please try again.");
          }
        } else {
          toast.error("Failed to process request. Please try again.");
        }
      }
    }
  };

  const textColors = [
    "#ffffff",
    "#ffffff",
    "#ffffff",
    "#ffffff",
    "#000000",
    "#000000",
    "#000000",
    "#000000",
    "#ffffff",
    "#ffffff",
    "#ffffff",
    "#ffffff",
    "#000000",
    "#000000",
    "#000000",
    "#000000",
    "#ffffff",
    "#ffffff",
    "#ffffff",
    "#ffffff",
  ];

  const backgroundColors = [
    "#ff0000",
    "#ff7f00",
    "#ffff00",
    "#00ff00",
    "#0000ff",
    "#4b0082",
    "#9400d3",
    "#ff69b4",
    "#ff0000",
    "#ff7f00",
    "#ffff00",
    "#00ff00",
    "#0000ff",
    "#4b0082",
    "#9400d3",
    "#ff69b4",
    "#ff0000",
    "#ff7f00",
    "#ffff00",
    "#00ff00",
  ];

  useEffect(() => {
    if (showModal) {
      console.log("Modal is shown, capturing and uploading screenshot...");
    }
  }, [showModal]);

  if (loading) {
    return <LoadingScreenHome />;
  }

  return (
    <>
      <div className="background overflow-hidden">
        <div className="mt-10">
          {/* <div className="absolute top-4 right-10">
            {authCheck ? (
              <Link
                to="/dashboard"
                className="bg-black/50 flex justify-center items-center gap-2 hover:bg-black/60 hover:text-white duration-300 ease-in-out py-2 px-8 rounded-sm text-white font-semibold">
                <i className="fa fa-user"></i>
                <p>Dashboard</p>
              </Link>
            ) : (
              <>
                <Link
                  to="/auth"
                  className="bg-black/50 flex justify-center items-center gap-2 hover:bg-black/60 hover:text-white duration-300 ease-in-out py-2 px-8 rounded-sm text-gray-200 font-semibold">
                  <i className="fa fa-user"></i>
                  <p>Login</p>
                </Link>
              </>
            )}
          </div> */}
          <div className="relative">
            <div className="justify-center border-8 border-dashed border-white/15 border-spacing-96 rounded-full items-start flex -rotate-45">
              <Wheel
                className="wheel"
                spinDuration={0.9}
                textColors={textColors}
                backgroundColors={backgroundColors}
                mustStartSpinning={mustSpin}
                prizeNumber={prizeNumber}
                data={data}
                fontSize={16}
                outerBorderWidth={0}
                outerBorderColor="#184bc4"
                innerRadius={10}
                innerBorderColor="#fefefe"
                innerBorderWidth={0}
                radiusLineColor="#fefefe"
                radiusLineWidth={0}
                perpendicularText={false}
                disableInitialAnimation={false}
                onStopSpinning={() => {
                  setMustSpin(false);
                  setTimeout(() => {
                    setShowModal(true);
                  }, 700);
                }}
              />
            </div>
            <img
              src={require("../images/logo.JPG")}
              className="logo-center z-50 w-16 md:w-20"
              alt="Logo"
            />
          </div>

          <div className="flex justify-center mt-4">
            <input
              onChange={(e) => setFormInput(e.target.value)}
              value={formInput}
              type="text"
              name="redeem"
              className="w-full focus:placeholder-transparent bottom-0 py-2 bg-black focus:bg-opacity-50 bg-opacity-40 outline-none text-center font-bold text-violet-100 cursor-pointer hover:bg-opacity-50"
              placeholder="REDEEM CODE"
            />
          </div>
          <div className="flex justify-center mt-4">
            <button
              onClick={handleSpinClick}
              className="relative inline-flex items-center justify-center p-4 px-5 py-3 overflow-hidden font-medium text-indigo-600 transition duration-300 ease-out rounded-full shadow-xl group hover:ring-1 hover:ring-purple-500">
              <span className="absolute inset-0 w-full h-full bg-gradient-to-br from-blue-600 via-purple-600 to-pink-700"></span>
              <span className="absolute bottom-0 right-0 block w-64 h-64 mb-32 mr-4 transition duration-500 origin-bottom-left transform rotate-45 translate-x-24 bg-pink-500 rounded-full opacity-30 group-hover:rotate-90 ease"></span>
              <span className="relative text-white">GET REWARDS</span>
            </button>
          </div>
        </div>
      </div>

      {showModal && (
        <dialog id="my_modal_4" className="modal w-screen h-screen" open>
          <div
            ref={modalRef}
            className="w-full h-full bg-white/10 backdrop-blur-sm rounded-xl p-4">
            <div
              style={{
                backgroundImage: `url(${barText})`,
                backgroundSize: "contain",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
                height: "100vh",
              }}
              className="flex h-96 justify-center items-center pt-20 pb-14">
              <p
                className="font-bold text-white mb-14 xl:mb-20 text-2xl xl:text-6xl"
                id="dynamicText">
                {reward}
              </p>
            </div>
          </div>
          <div className="modal-action absolute top-4 right-12">
            <button
              onClick={() => setShowModal(false)}
              className="relative inline-flex items-center justify-center p-4 px-5 py-3 overflow-hidden font-medium text-indigo-600 transition duration-300 ease-out rounded-full shadow-xl group hover:ring-1 hover:ring-purple-500">
              <span className="absolute inset-0 w-full h-full bg-gradient-to-br from-blue-600 via-purple-600 to-pink-700"></span>
              <span className="absolute bottom-0 right-0 block w-64 h-64 mb-32 mr-4 transition duration-500 origin-bottom-left transform rotate-45 translate-x-24 bg-pink-500 rounded-full opacity-30 group-hover:rotate-90 ease"></span>
              <span className="relative text-white">X Close</span>
            </button>
          </div>
          <div className="modal-action absolute bottom-1/3 text-red-900 bg-yellow-500 p-2 rounded-sm">
            <h1>
              Silahkan Screenshot hadiah anda dan kirim ke admin melalui
              whatsapp dibawah.
            </h1>
          </div>
          <div className="modal-action absolute bottom-1/4">
            <a
              href={`https://wa.me/${phoneNumber}?text=Hello,%20I%20want%20to%20confirm%20the%20reward!`}
              target="_blank"
              rel="noopener noreferrer"
              className="relative inline-flex items-center justify-center p-4 px-5 py-3 overflow-hidden font-medium text-indigo-600 transition duration-300 ease-out rounded-full shadow-xl group hover:ring-1 hover:ring-purple-500">
              <span className="absolute inset-0 w-full h-full bg-gradient-to-br from-green-600 via-lime-600 to-yellow-700"></span>
              <span className="absolute bottom-0 right-0 block w-64 h-64 mb-32 mr-4 transition duration-500 origin-bottom-left transform rotate-45 translate-x-24 bg-pink-500 rounded-full opacity-30 group-hover:rotate-90 ease"></span>
              <span className="relative text-white fa fa-whatsapp mr-1"></span>
              <span className="relative text-white">Confirm Reward</span>
            </a>
          </div>
        </dialog>
      )}

      <audio
        ref={audioRef}
        src={winningSound}
        onCanPlayThrough={() => {
          console.log("Audio can play through.");
        }}
        onError={(e) => {
          console.error("Error loading audio:", e);
        }}
      />
    </>
  );
};
