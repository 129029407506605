// src/components/AddPhoneNumberModal.jsx

import React, { useState, useEffect } from "react";
import axios from "axios";
import { toast } from "react-toastify";

const AddPhoneNumberModal = ({ isOpen, onClose, onSuccess, initialPhone }) => {
  const [phone, setPhone] = useState("");

  // Update state when the modal opens and initialPhone is provided
  useEffect(() => {
    if (isOpen && initialPhone) {
      setPhone(initialPhone.phone);
    }
  }, [isOpen, initialPhone]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (initialPhone) {
        // Update existing phone number
        await axios.put(`api/phone-number/update`, { phone });
      } else {
        // Add new phone number
        await axios.post("api/phone-number/store", { phone });
      }
      toast.success("Phone number saved successfully!");
      onSuccess({ phone });
      onClose();
    } catch (err) {
      toast.error("Failed to save phone number!");
    }
  };

  return (
    isOpen && (
      <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50">
        <div className="bg-white rounded p-6">
          <h2 className="text-xl font-semibold mb-4">
            {initialPhone ? "Edit Phone Number" : "Add Phone Number"}
          </h2>
          <form onSubmit={handleSubmit}>
            <label className="block mb-2">
              Phone Number
              <input
                type="text"
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
                className="border border-gray-300 p-2 w-full"
                required
              />
            </label>
            <div className="flex justify-end gap-2">
              <button
                type="button"
                onClick={onClose}
                className="bg-gray-400 text-white active:scale-95 duration-200 px-4 py-2 rounded">
                Cancel
              </button>
              <button
                type="submit"
                className="bg-green-600 text-white active:scale-95 duration-200 px-4 py-2 rounded mr-2">
                Save
              </button>
            </div>
          </form>
        </div>
      </div>
    )
  );
};

export default AddPhoneNumberModal;
