import React from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import secureLocalStorage from "react-secure-storage";
export const Sidebar = () => {
  const location = useLocation();
  const navRedirect = useNavigate();
  const logoutNow = async (e) => {
    await axios.get("sanctum/csrf-cookie").then((res) => {
      axios.post(`api/logout`).then((res) => {
        secureLocalStorage.clear();
        toast.success("you are logged out!");
        navRedirect("/auth");
      });
    });
  };
  return (
    <div className="bg-white w-2/6 rounded-xl hidden md:block md:pb-10">
      <div
        onClick={() => navRedirect("/")}
        className="flex justify-start gap-4 p-10 items-center border-b cursor-pointer">
        <i className="fa fa-angle-left text-4xl font-bold text-yellow-500 "></i>
        <h1 className="text-gray-600 text-3xl">
          {secureLocalStorage.getItem("auth_name")}
        </h1>
      </div>
      <div className="font-normal">
        <div className="flex justify-start gap-4 px-10 py-2 items-center mt-4">
          <Link
            to="/profile"
            className={`${
              location.pathname === "/profile"
                ? " bg-yellow-600 text-white"
                : "text-gray-600 hover:bg-yellow-600 hover:text-white "
            } flex items-center glass gap-4 w-full px-4 py-2 rounded-lg duration-300 ease-in-out`}>
            <i className="fa fa-user text-3xl"></i>
            <h1 className="text-xl">Profile</h1>
          </Link>
        </div>
        <div className="flex justify-start gap-4 px-10 py-2 items-center mt-4">
          <Link
            to="/dashboard"
            className={`${
              location.pathname === "/dashboard"
                ? " bg-yellow-600 text-white"
                : "text-gray-600 hover:bg-yellow-600 hover:text-white "
            } flex items-center glass gap-4 w-full px-4 py-2 rounded-lg duration-300 ease-in-out`}>
            <i className="fa fa-table text-3xl"></i>
            <h1 className="text-xl">Dashboard</h1>
          </Link>
        </div>
        <div className="flex justify-start gap-4 px-10 py-2 items-center mt-52">
          <button
            onClick={logoutNow}
            className="cursor-pointer flex items-center gap-4 w-full px-4 py-2 rounded-lg text-gray-600 hover:bg-yellow-900 hover:text-white duration-300">
            <i className="fa fa-power-off text-3xl hover:text-white"></i>
            <h1 className="text-xl">Keluar dari akun</h1>
          </button>
        </div>
      </div>
    </div>
  );
};
