import React, { useEffect, useState } from "react";
import secureLocalStorage from "react-secure-storage";
import { Login } from "./Login";
import { Register } from "./Register";
import "../Assets/whell.css";

export const Auth = ({ setAuthCheck }) => {
  useEffect(() => {
    setAuthCheck(secureLocalStorage.getItem("auth_token") ? true : false);
  });
  return (
    <div className="background">
      <div className="lg:container-lg bg-black/50 lg:mx-auto flex justify-center mt-10 mx-2 ">
        <div className="rounded-lg  w-96 p-8 ">
          <h1 className="font-semibold text-white text-xl">Masuk</h1>
          <Login setAuthCheck={setAuthCheck} />
        </div>
      </div>
    </div>
  );
};
