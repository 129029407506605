// InputReedem.jsx

import React, { useState, useEffect } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { Sidebar } from "../Components/Sidebar";
import AddReedemModal from "./Components/AddReedemModal";
import AddPhoneNumberModal from "./Components/AddPhoneNumberModal"; // Komponen baru
import LoadingScreen from "../Components/LoadingScreen";

export const InputReedem = () => {
  const [reedemList, setReedemList] = useState([]);
  const [phoneNumber, setPhoneNumber] = useState(null); // Gunakan satu state untuk nomor telepon
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isPhoneModalOpen, setIsPhoneModalOpen] = useState(false); // Tambah state untuk modal nomor telepon
  const [selectedReedem, setSelectedReedem] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    getReedemAPI();
    getPhoneNumberAPI(); // Ambil nomor telepon dari API
  }, []);

  const getReedemAPI = async () => {
    try {
      const res = await axios.get("api/reward/get");
      setReedemList(res.data);
    } catch (err) {
      toast.error("Failed to fetch reedem data!");
    }
  };

  const getPhoneNumberAPI = async () => {
    try {
      await axios.get("sanctum/csrf-cookie");
      const res = await axios.get("api/phone-number/get"); // Ambil nomor telepon
      setPhoneNumber(res.data); // Set satu nomor telepon
    } catch (err) {
      toast.error("Failed to fetch phone number!");
    }
  };

  const handleAddReedem = (reedem, isEditing) => {
    if (isEditing) {
      setReedemList((prev) =>
        prev.map((item) => (item.id === selectedReedem.id ? reedem : item))
      );
    } else {
      setReedemList([...reedemList, reedem]);
    }
  };

  const handleAddPhoneNumber = (phoneNumber) => {
    setPhoneNumber(phoneNumber); // Set nomor telepon yang baru
  };

  const handleEdit = (reedem) => {
    setSelectedReedem(reedem);
    setIsModalOpen(true);
  };

  const handleDelete = async (id) => {
    setIsLoading(true);
    try {
      await axios.delete(`api/reward/delete/${id}`);
      setReedemList(reedemList.filter((item) => item.id !== id));
      toast.success("Data berhasil dihapus!");
    } catch (err) {
      toast.error("Gagal menghapus data!");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      {isLoading && <LoadingScreen />}
      <div className="bg-gray-100 md:pt-10 md:pb-10 px-4 pt-4">
        <div className="lg:container lg:mx-auto">
          <div className="md:flex md:columns-2 md:gap-10">
            <Sidebar />
            <div className="bg-white rounded-xl md:w-5/6">
              <div className="flex justify-start gap-2 items-baseline p-3 border-b">
                <button
                  onClick={() => {
                    setSelectedReedem(null);
                    setIsModalOpen(true);
                  }}
                  className="bg-teal-600 flex items-center gap-1 text-white px-4 py-1 rounded text-sm">
                  + Reedem
                </button>
                <button
                  onClick={() => setIsPhoneModalOpen(true)} // Tambah tombol untuk modal nomor telepon
                  className="bg-green-600 flex items-center gap-1 text-white px-4 py-1 rounded text-sm">
                  <i className="fa fa-whatsapp"></i>
                  <p>WhatsApp</p>
                </button>
              </div>
              <div className="overflow-x-auto">
                <table className="min-w-full bg-white table-auto">
                  <thead>
                    <tr>
                      <th className="py-2">id</th>
                      <th className="py-2">Kode Reedem</th>
                      <th className="py-2">Reward</th>
                      <th className="py-2">Jumlah Maksimal</th>
                      <th className="py-2">Aksi</th>
                    </tr>
                  </thead>
                  <tbody>
                    {reedemList.map((item) => (
                      <tr key={item.id}>
                        <td className="border px-4 py-2">{item.id}</td>
                        <td className="border px-4 py-2">{item.code}</td>
                        <td className="border px-4 py-2">{item.reward}</td>
                        <td className="border px-4 py-2">{item.max}</td>
                        <td className="border px-4 py-2 flex justify-start items-center gap-2">
                          <button
                            onClick={() => handleEdit(item)}
                            className="bg-yellow-500 text-white px-4 py-2 rounded w-full">
                            Update
                          </button>
                          <button
                            disabled={isLoading}
                            onClick={() => handleDelete(item.id)}
                            className="bg-red-500 text-white px-4 py-2 rounded w-full">
                            "Delete!"
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <AddReedemModal
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          onSuccess={handleAddReedem}
          initialData={selectedReedem}
        />
        <AddPhoneNumberModal
          isOpen={isPhoneModalOpen}
          onClose={() => setIsPhoneModalOpen(false)}
          onSuccess={handleAddPhoneNumber}
          initialPhone={phoneNumber} // Pass the current phone number to the modal
        />
      </div>
    </>
  );
};
