import axios from "axios";
import { useEffect, useState } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import secureLocalStorage from "react-secure-storage";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./App.css";
import { InputReedem } from "./pages/Admin/InputReedem";
import { Profile } from "./pages/Admin/Profile";
import { Auth } from "./pages/Auth";
import { Navbar } from "./pages/Components/Navbar";
import { Home } from "./pages/Home";
import { Loader } from "./pages/Loader";

axios.defaults.baseURL = process.env.REACT_APP_API;
axios.defaults.headers.post["Accept"] = "application/json";
axios.defaults.headers.post["Content-Type"] = "application/json";
axios.defaults.withCredentials = true;

// Set up axios interceptor for authentication
axios.interceptors.request.use(
  (config) => {
    const token = secureLocalStorage.getItem("auth_token");
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

function App() {
  const [authCheck, setAuthCheck] = useState(false);

  // Function to check authentication status
  const checkAuth = async () => {
    try {
      await axios.get("sanctum/csrf-cookie");
      await axios.post("api/onload");
      setAuthCheck(true);
    } catch (error) {
      secureLocalStorage.clear();
      setAuthCheck(false);
    }
  };

  useEffect(() => {
    const token = secureLocalStorage.getItem("auth_token");
    if (token) {
      checkAuth();
    } else {
      setAuthCheck(false);
    }
  }, []);
  const location = useLocation();
  return (
    <>
      <ToastContainer />
      {(location.pathname === "/dashboard" ||
        location.pathname === "/profile") && <Navbar />}

      <Routes>
        <Route
          path="/"
          element={<Home authCheck={authCheck} setAuthCheck={setAuthCheck} />}
        />
        <Route
          path="*"
          element={<Home authCheck={authCheck} setAuthCheck={setAuthCheck} />}
        />
        {authCheck && <Route path="/dashboard" element={<InputReedem />} />}
        {authCheck && <Route path="/profile" element={<Profile />} />}
        <Route path="/a/loader" element={<Loader />} />
        <Route path="/Auth" element={<Auth setAuthCheck={setAuthCheck} />} />
      </Routes>
    </>
  );
}

export default App;
