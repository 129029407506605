import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import secureLocalStorage from "react-secure-storage";
import { toast } from "react-toastify";
export const Navbar = ({ authCheck, setAuthCheck }) => {
  const navRedirect = useNavigate();
  const [showList, setShowList] = useState(false);
  const handleToggle = () => {
    setShowList(showList ? false : true);
  };
  const location = useLocation();

  const logoutNow = async (e) => {
    await axios.get("sanctum/csrf-cookie").then((res) => {
      axios.post(`api/logout`).then((res) => {
        toast.success("you are logged out!");
        navRedirect("/auth");
        secureLocalStorage.clear();
        handleDropdown();
      });
    });
  };
  const [isDropdown, setDropdown] = useState(false);
  const handleDropdown = () => {
    setDropdown(isDropdown === false ? true : false);
  };

  return (
    <>
      <div className="text-center bg-yellow-900 text-white py-3 text-md">
        you have a redeem code?{" "}
        <Link to="/" className="text-yellow-500 underline">
          di sini
        </Link>{" "}
      </div>
      <nav className="bg-light border-b py-2 sticky top-0 bg-white">
        <div className="mx-auto max-w-7xl px-2 sm:px-6 lg:px-8">
          <div className="relative flex h-16 items-center justify-between">
            <div className="absolute inset-y-0 left-0 flex items-center sm:hidden">
              <button
                onClick={handleToggle}
                type="button"
                className="inline-flex items-center justify-center rounded-md p-2 text-yellow-700 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                aria-controls="mobile-menu"
                aria-expanded="false">
                <span className="sr-only">Open main menu</span>

                <svg
                  className="block h-6 w-6"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="currentColor"
                  aria-hidden="true">
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"
                  />
                </svg>

                <svg
                  className="hidden h-6 w-6"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="currentColor"
                  aria-hidden="true">
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
            </div>
            <div className="flex flex-1 items-center justify-center sm:items-stretch sm:justify-start">
              <Link
                to="/"
                className="flex-shrink-0 items-center hidden md:flex">
                <img
                  className="rounded-full"
                  src={require("../../images/logo.JPG")}
                  width={40}
                  height={100}
                  alt=""
                />
                <span className="text-red-700 font-bold -ml-28 md:ml-1 mr-10">
                  QWERTYJOKI
                </span>
              </Link>
            </div>
            <div className="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
              {secureLocalStorage.getItem("auth_token") ? (
                <div className="cursor-pointer" onClick={handleDropdown}>
                  <div className="uppercase text-sm md:text-xl bg-gray-50 hover:bg-gray-100 duration-300 ease-in-out py-2 px-5 rounded-sm border font-semibold">
                    <i className="fa fa-user-circle text-gray-800 mr-2"></i>
                    {secureLocalStorage.getItem("auth_name").split(" ")[0]}
                  </div>
                </div>
              ) : (
                <>
                  <Link
                    to="/auth"
                    className="bg-gray-50 hover:bg-gray-100 duration-300 ease-in-out py-2 px-2 rounded-sm border text-yellow-500 font-semibold">
                    Login
                  </Link>
                </>
              )}
            </div>
          </div>
        </div>

        <div
          className={`${showList ? "md:hidden" : "hidden"}`}
          id="mobile-menu">
          {" "}
          {secureLocalStorage.getItem("auth_token") && (
            <div className="space-y-1 px-2 pt-2 pb-3">
              <Link
                onClick={handleToggle}
                to="/profile"
                className={`${
                  location.pathname === "/profile"
                    ? "text-yellow-500"
                    : "text-slate-500"
                }  hover:text-yellow-500 block px-3 py-2 rounded-md text-base font-medium`}
                aria-current="page">
                Profile
              </Link>
              <Link
                onClick={handleToggle}
                to="/dashboard"
                className={`${
                  location.pathname === "/dashboard"
                    ? "text-yellow-500"
                    : "text-slate-500"
                }  hover:text-yellow-500 block px-3 py-2 rounded-md text-base font-medium`}
                aria-current="page">
                Dashboard
              </Link>
              <button
                onClick={logoutNow}
                className={`${
                  location.pathname === "/"
                    ? "text-yellow-500"
                    : "text-slate-500"
                }  hover:text-yellow-500 block px-3 py-2 rounded-md text-base font-medium`}>
                Logout
              </button>
            </div>
          )}
        </div>
      </nav>
    </>
  );
};
